import { useCallback, useEffect } from 'react'

import { config } from 'config'
import type { Organization as GraphqlOrganization } from 'modules/api/generated/graphql'
import { WorkspaceSubscription } from 'modules/api/generated/graphql'
import { fetchHMAC } from 'modules/auth/utils'
import { useFeatureFlag } from 'modules/featureFlags'
import { SupportedLocaleKey } from 'modules/i18n/constants'
import { useLinguiLocale } from 'modules/i18n/hooks/useLinguiLocale'
import { useLaunchIntercom } from 'modules/intercom/hooks'
import { PaidProductKey } from 'modules/monetization'
import {
  getProductForWorkspace,
  getProOrPlusProductPrice,
} from 'modules/monetization/utils'
import { analytics, SegmentEvents } from 'modules/segment'
import { useUserContext } from 'modules/user'
import { isRobot } from 'utils/deviceDetection'
import { useScriptInnerHTML } from 'utils/hooks/useScript'
import { shouldUsePublishedVersion } from 'utils/publishing'

import {
  Customer,
  FreeformFeedback,
  SurveyResponse,
  SessionResults,
  ChurnkeyCancelResult,
} from './types'
import { getChurnkeyMessageDictionary } from './utils'

const CHURNKEY_SNIPPET = `
!function(){  
    if (!window.churnkey || !window.churnkey.created) {
      window.churnkey = { created: true };
      const a = document.createElement('script');
      a.src = 'https://assets.churnkey.co/js/app.js?appId=${config.CHURNKEY_APP_ID}';
      a.async = true;
      const b = document.getElementsByTagName('script')[0];
      b.parentNode.insertBefore(a, b);
    }
  }();
`

export const ChurnkeyScript = () => {
  const enabled = !isRobot() && !shouldUsePublishedVersion()
  useScriptInnerHTML(enabled, CHURNKEY_SNIPPET)

  return null
}

export const useChurnkeyFailedPaymentCheck = ({
  subscription,
  workspace,
  autoLaunch = true,
}: {
  subscription?: WorkspaceSubscription
  workspace?: GraphqlOrganization
  autoLaunch?: boolean
}) => {
  const { user } = useUserContext()
  const featureFlagEnabled = useFeatureFlag('churnkeyFailedPaymentWallEnabled')
  const localeKey = useLinguiLocale()
  const productKey = getProductForWorkspace(workspace)
  const launchIntercomUI = useLaunchIntercom()

  const enabled =
    featureFlagEnabled && user?.id === subscription?.createdByUser?.id

  const initiate = useCallback(async () => {
    if (!subscription || !productKey || !enabled) return

    return fetchHMAC('churnkey').then((hmac) => {
      if (
        !hmac ||
        !subscription.billingSubscriptionId ||
        !subscription.createdByUser?.billingCustomerId
      ) {
        console.error(
          '[Churnkey] launchFailedPayment Missing hmac or subscription data',
          {
            hmac,
            subscription,
          }
        )
        return
      }
      console.debug(
        '[Churnkey] launchFailedPayment - launching churnkey with:',
        {
          hmac,
          appId: config.CHURNKEY_APP_ID,
          subscriptionId: subscription.billingSubscriptionId,
          customerId: subscription.createdByUser?.billingCustomerId,
        }
      )
      launchChurnkeyFailedPayment({
        authHash: hmac,
        subscription,
        localeKey,
        productKey,
        launchIntercomUI,
      })
    })
  }, [subscription, productKey, enabled, localeKey, launchIntercomUI])

  useEffect(() => {
    if (!autoLaunch) return
    initiate()
  }, [initiate, autoLaunch])

  return {
    launchFailedPaymentFlow: initiate,
    failedPaymentFlowEnabled: enabled,
  }
}

export const launchChurnkeyFailedPayment = async ({
  authHash,
  subscription,
  localeKey,
  productKey,
  launchIntercomUI,
}: {
  authHash: string
  subscription: WorkspaceSubscription
  localeKey: SupportedLocaleKey
  productKey: PaidProductKey
  launchIntercomUI: ReturnType<typeof useLaunchIntercom>
}) => {
  // See https://docs.churnkey.co/failed-payment-wall
  window.churnkey?.check('failed-payment', {
    subscriptionId: subscription.billingSubscriptionId, // required
    customerId: subscription.createdByUser?.billingCustomerId, // required
    authHash, // required
    customerAttributes: {
      language: localeKey,
    },
    i18n: {
      lang: localeKey,
      messages: getChurnkeyMessageDictionary(localeKey, productKey),
    },
    appId: config.CHURNKEY_APP_ID, // required
    mode: config.CHURNKEY_MODE, // set to 'test' to hit test billing provider environment
    provider: 'stripe',
    softWall: true, // set to true to allow exiting wall (if true makes gracePeriodDays meaningless)
    forceCheck: false, // recommended to leave this to false to avoid redundant checks
    gracePeriodDays: 3, // allow a grace period in which failed payment wall is shown but can be exited (soft wall) (only applies if softWall=false)
    ignoreInvoicesWithoutAttempt: false, // set to true to ignore invoices without a failed charge
    // handleLogout() {
    //   // optional, if defined, a "Logout" button will show
    // },
    handleSupportRequest() {
      // optional, if defined, a "Contact Support" button will show
      launchIntercomUI()
      analytics.track(SegmentEvents.CHURNKEY_HANDLE_SUPPORT_CLICK, {
        source: 'failed-payment-wall',
        subscriptionId: subscription.billingSubscriptionId,
        customerId: subscription.createdByUser?.billingCustomerId,
      })
    },
    async handleCancel(customer: Customer) {
      // optional, if defined, a "Cancel Subscription" button will show
      analytics.track(SegmentEvents.CANCEL_SUBSCRIPTION_CLICK, {
        source: 'failed-payment-wall',
        subscriptionId: subscription.billingSubscriptionId,
        customerId: subscription.createdByUser?.billingCustomerId,
      })
      console.debug('[launchChurnkeyFailedPayment.handleCancel]', {
        subscriptionId: subscription.billingSubscriptionId,
        customerId: subscription.createdByUser?.billingCustomerId,
        customer,
      })
      return launchChurnkeyCancelFlow({
        authHash,
        subscription,
        localeKey,
        productKey,
        launchIntercomUI,
        customerAttributes: {
          viaFailedPayment: true,
        },
      }).catch((_error) => {
        // This is a workaround for handling the case where the user aborts the cancel flow
        // Ideally we would just go back to the credit card form, but Churnkey doesn't support that yet,
        // so we re-launch the failed payment flow.
        return launchChurnkeyFailedPayment({
          authHash,
          subscription,
          localeKey,
          productKey,
          launchIntercomUI,
        })
      })
    },
    onFailedPaymentWallActivated() {
      // optional, called when wall is activated
      analytics.track(SegmentEvents.CHURNKEY_FAILED_PAYMENT_WALL_SHOWN, {
        subscriptionId: subscription.billingSubscriptionId,
        customerId: subscription.createdByUser?.billingCustomerId,
      })
    },
    onUpdatePaymentInformation() {
      // optional, called after customer updates payment information and overdue invoice is charged
      analytics.track(SegmentEvents.CHURNKEY_PAYMENT_METHOD_UPDATED, {
        source: 'failed-payment-wall',
        subscriptionId: subscription.billingSubscriptionId,
        customerId: subscription.createdByUser?.billingCustomerId,
      })
    },
    onFailedPaymentWallClose() {
      analytics.track(SegmentEvents.CHURNKEY_FAILED_PAYMENT_WALL_CLOSED, {
        subscriptionId: subscription.billingSubscriptionId,
        customerId: subscription.createdByUser?.billingCustomerId,
      })
    },
    onCancel() {
      // optional, called after customer cancels subscription
      analytics.track(SegmentEvents.CHURNKEY_CANCEL_SUBSCRIPTION_COMPLETE, {
        source: 'failed-payment-wall',
        subscriptionId: subscription.billingSubscriptionId,
        customerId: subscription.createdByUser?.billingCustomerId,
      })
    },
    onError(error: Error, type: any) {
      console.warn('Churnkey check("failed-payment", ...) error', error, type)
    },
  })
}

export const useChurnkeyCancelFlow = (
  subscription?: WorkspaceSubscription,
  workspace?: GraphqlOrganization
) => {
  const enabled = useFeatureFlag('churnkeyCancelFlowEnabled')
  const localeKey = useLinguiLocale()
  const productKey = getProductForWorkspace(workspace)
  const launchIntercomUI = useLaunchIntercom()

  const launchCancelFlow = useCallback(() => {
    if (!subscription || !productKey || !enabled) return

    return fetchHMAC('churnkey').then((hmac) => {
      if (
        !hmac ||
        !subscription.billingSubscriptionId ||
        !subscription.createdByUser?.billingCustomerId
      ) {
        console.debug(
          '[Churnkey] handleCancel - Missing hmac or subscription data',
          {
            hmac,
            subscription,
          }
        )
        return
      }
      return launchChurnkeyCancelFlow({
        authHash: hmac,
        subscription,
        localeKey,
        productKey,
        launchIntercomUI,
      })
    })
  }, [enabled, localeKey, productKey, subscription, launchIntercomUI])

  return {
    launchCancelFlow,
    cancelFlowEnabled: enabled,
  }
}

export const launchChurnkeyCancelFlow = async ({
  authHash,
  subscription,
  localeKey,
  productKey,
  customerAttributes = {},
  launchIntercomUI,
}: {
  authHash: string
  subscription: WorkspaceSubscription
  localeKey: SupportedLocaleKey
  productKey: PaidProductKey
  customerAttributes?: Record<string, any>
  launchIntercomUI: ReturnType<typeof useLaunchIntercom>
}) => {
  return new Promise<ChurnkeyCancelResult>((resolve, reject) => {
    window.churnkey?.init('show', {
      subscriptionId: subscription.billingSubscriptionId, // recommended unless Paddle
      customerId: subscription.createdByUser?.billingCustomerId, // required unless Paddle
      authHash, // required
      customerAttributes: {
        language: localeKey,
        frequency: getProOrPlusProductPrice(subscription)?.frequencyUnit,
        productKey,
        ...customerAttributes,
      },
      i18n: {
        lang: localeKey,
        messages: getChurnkeyMessageDictionary(localeKey, productKey),
      },
      appId: config.CHURNKEY_APP_ID, // required
      mode: config.CHURNKEY_MODE, // set to 'test' to hit test billing provider environment
      provider: 'stripe', // set to 'stripe', 'chargebee', 'braintree', 'paddle'
      record: true, // set to false to skip session playback recording
      // async handlePause(customer, { pauseDuration }): Promise<any> {},
      // async handleCancel(_customer: Customer): Promise<any> {}, // Override default cancel behavior
      // async handleDiscount(customer, coupon): Promise<any> {},
      // async handleTrialExtension(
      //   customer,
      //   { trialExtensionDays }
      // ): Promise<any> {},
      // async handlePlanChange(customer, { plan }): Promise<any> {},
      handleSupportRequest(): void {
        launchIntercomUI()
        analytics.track(SegmentEvents.CHURNKEY_HANDLE_SUPPORT_CLICK, {
          source: 'cancel-flow',
          subscriptionId: subscription.billingSubscriptionId,
          customerId: subscription.createdByUser?.billingCustomerId,
        })
      },
      onCancel(
        customer: Customer,
        surveyResponse: SurveyResponse,
        freeformFeedback: FreeformFeedback
      ): void {
        analytics.track(SegmentEvents.CHURNKEY_CANCEL_SUBSCRIPTION_COMPLETE, {
          source: 'cancel-flow',
          subscriptionId: subscription.billingSubscriptionId,
          customerId: subscription.createdByUser?.billingCustomerId,
          surveyResponse,
          freeformFeedback,
        })
        console.debug('[launchChurnkeyCancelFlow.onCancel]', {
          surveyResponse,
          freeformFeedback,
        })
      },
      // onPause(customer, { pauseDuration }): void {
      //   resolve(true)
      // },
      // onDiscount(customer, coupon): void {},
      // onTrialExtension(customer, { trialExtensionDays }): void {},
      // onPlanChange(customer, { planId }): void {},
      onGoToAccount(sessionResults: SessionResults): void {
        console.debug('[launchChurnkeyCancelFlow.onGoToAccount]', {
          sessionResults,
        })
        resolve({ sessionResults })
      },
      onClose(sessionResults: SessionResults): void {
        console.debug('[launchChurnkeyCancelFlow.onClose]', {
          sessionResults,
        })
        if (!sessionResults || sessionResults.aborted) {
          reject('Churnkey cancel flow aborted')
          return
        }
        resolve({ sessionResults })
      },
      // onSessionComplete(sessionResults, customer): void {},
      onError(message?: string): void {
        console.warn('[launchChurnkeyCancelFlow.onError]', {
          message,
        })
        reject({ message })
      },
    })
  })
}
