import { useEffect } from 'react'

import { useLinguiLocale } from './useLinguiLocale'

export const useSyncLangHTMLAttr = () => {
  const locale = useLinguiLocale()
  useEffect(() => {
    document.documentElement.setAttribute('lang', locale)
  }, [locale])
}
