import { useEffect, useRef } from 'react'

import { config } from 'config'
import { AppMonitoringEvents } from 'modules/segment'
import { analytics } from 'modules/segment/AnalyticsWrapper'

const MINIMUM_EVENTS = 5
const REPORTING_THRESHOLD = 0.5

type EventCountTypes = 'total' | 'notTrusted'

export const useBotDetection = () => {
  const hasBeenReported = useRef(false)
  const eventCounts = useRef(new Map<EventCountTypes, number>())

  useEffect(() => {
    if (config.APPLICATION_ENVIRONMENT !== 'production') return

    const maybeReport = () => {
      // If the percentage of untrusted events is greater than the threshold, report it
      if (hasBeenReported.current) return

      const totalEvents = eventCounts.current.get('total')!
      const notTrustedEvents = eventCounts.current.get('notTrusted')!
      const thresholdExceeded =
        notTrustedEvents / totalEvents > REPORTING_THRESHOLD

      if (thresholdExceeded && totalEvents > MINIMUM_EVENTS) {
        hasBeenReported.current = true
        analytics.track(AppMonitoringEvents.NOT_TRUSTED_EVENTS_DETECTED, {
          totalEvents,
          notTrustedEvents,
        })
      }
    }

    // Create event listeners for common events (on capture phase) and check if the event is trusted
    // https://developer.mozilla.org/en-US/docs/Web/API/Event/isTrusted
    const handler = (e: Event) => {
      if (!e.isTrusted) {
        eventCounts.current.set(
          'notTrusted',
          (eventCounts.current.get('notTrusted') || 0) + 1
        )
      }
      eventCounts.current.set(
        'total',
        (eventCounts.current.get('total') || 0) + 1
      )
      maybeReport()
    }

    document.addEventListener('paste', handler, { capture: true })
    document.addEventListener('mousedown', handler, {
      capture: true,
    })
    document.addEventListener('keydown', handler, { capture: true })

    return () => {
      document.removeEventListener('paste', handler, { capture: true })
      document.removeEventListener('mousedown', handler, {
        capture: true,
      })
      document.removeEventListener('keydown', handler, {
        capture: true,
      })
    }
  }, [])
}
