import { t } from '@lingui/macro'
import capitalize from 'lodash/capitalize'

import { SupportedLocaleKey } from 'modules/i18n/constants'
import { GAMMA_PROPER_NOUN } from 'modules/i18n/properNouns'
import { PaidProductKey } from 'modules/monetization/types'

export const getChurnkeyMessageDictionary = (
  languageKey: SupportedLocaleKey,
  paidProductKey: PaidProductKey
) => {
  const productName = capitalize(paidProductKey)

  // Full dictionary of all Churnkey terms, translated for our app.
  // See their full  https://docs.churnkey.co/installing-churnkey#36541a55df4c464f86f04c62c4cbc0b8
  return {
    [languageKey]: {
      next: t`Next`,
      back: t`Back`,
      nevermind: t`Go back`,
      goToAccount: t`Back to ${GAMMA_PROPER_NOUN}`,
      getHelp: t`Something wrong? Contact us...`,
      declineOffer: t`Decline offer`,
      confirmAndCancel: t`Confirm & cancel`,
      pauseSubscription: t`Pause subscription`,
      cancelSubscription: t`Cancel`,
      discountSubscription: t`Accept this offer`, // button to accept discount offer
      claimOffer: t`Claim your limited-time offer`, // shown above discount offers
      discountOff: t`off`, // e.g. "10% _off_"
      discountFor: t`for`, // e.g. "20% off _for_ 2 months"
      discountForever: t`for life`, // e.g. "10% off _for life_"
      discountOneTime: t`your next renewal`, // e.g. "10% off _your next renewal_"
      day: t`Day | Days`, // e.g. "1 day" "2 days"
      month: t`month | months`, // e.g. "1 month" "2 months"
      year: t`year | years`, // e.g. "1 year" "2 years"
      error: t`Sorry, something went wrong`, // generic error message
      genericErrorDescription: t`Please contact us.`, // generic error message
      cancelNow: t`Cancel subscription →`, // button to cancel subscription immediately
      applyingDiscount: t`Applying discount...`, // shown while applying discount
      applyingCancel: t`Cancelling subscription...`, // shown while cancelling subscription
      applyingResume: t`Resuming subscription...`, // shown while resuming a paused subscription
      applyingPause: t`Pausing subscription...`, // shown while pausing subscription
      discountApplied: t`Discount applied.`, // shown when discount is applied
      discountAppliedMessage: t`We're so happy you're still here`, // shown when discount is applied
      pauseApplied: t`Subscription paused.`, // shown when subscription is paused
      pauseAppliedMessage: t`You won't be billed until your subscription resumes`, // shown when subscription is paused
      pauseAppliedResumeMessage: t`Your subscription will resume on`, // shown when subscription is paused
      pauseScheduledMessage: t`Your subscription will be paused beginning`, // shown when subscription is scheduled in the future
      until: t`until`,
      cancelApplied: t`Subscription cancelled.`, // shown when subscription is canceled
      cancelAppliedMessage: t`You won't be billed again`, // shown when subscription is canceled
      cancelAppliedDateMessage: t`Your subscription will end on`, // shown when subscription is canceled
      howLongToPausePrompt: t`Choose how long you want to pause...`, // shown above the pause subscription prompt
      whatCouldWeHaveDone: t`What could we have done better?`, // shown above the feedback prompt
      weReadEveryAnswer: t`We read every answer...`, // shown as placeholder in the feedback prompt
      applyingCustomerAction: t`This will just take a second.`, // shown while applying customer action
      loading: t`Loading...`, // shown while loading
      pauseWallCardPunch: t`Want access?`,
      pauseWallCta: t`Resume subscription now`,
      pauseWallCardHeading: t`Resume your subscription`,
      scheduledToReactivate: t`It's scheduled to reactivate on`,
      resumeApplied: t`Subscription resumed`, // shown when subscription is paused
      resumeAppliedMessage: t`You will be billed at your next subscription renewal period.`, // shown when subscription is paused
      resumeNextChargeMessage: t`Upon reactivation, you'll be charged at your original rate of `,
      resumeNextChargeMessageWithoutAmount: t`Upon reactivation, you'll be charged at your usual rate.`,
      resumeAccountDataInfo: t`Your account data is being kept safe for when your subscription resumes.`,
      subscriptionPauseNotice: t`Looks like your subscription is still paused`,
      failedPaymentNotice: t`Uh-oh, we couldn't charge your card`,
      chargedMultipleTimeNotice: t`We've tried a number of times to charge your card on file. We'd hate to lose you!`,
      failedPaymentCardPunch: t`Please update your payment details to avoid losing your ${GAMMA_PROPER_NOUN} ${productName} features.`,
      resumeHey: t`Hey`,
      invoicePaidTitle: t`Invoice paid successfully`,
      logout: t`Logout →`, // used on failed payment and pause wall
      // ex You have a 20% off for 3 months discount valid until October 15. Your existing discount will be overriden upon accepting a new offer.
      note: t`Note:`, // shown before the discount note
      discount: t`discount`,
      discountNoticeHeadline: t`Note: you'll also lose an active discount.`,
      discountNoticePrepend: t`If you cancel now, you'll lose your current`,
      discountOverride: t`if you take this offer, you'll lose your current`,
      discountValidUntil: t`It's good until`, // shown on active discount
      updateBilling: t`Update card`,
      // Trial Extension
      extendTrialCTA: t`Extend trial by `,
      extendTrialPunch: t`Your trial ends on `,
      extendTrialOfferTitle: t`Trial extension`,
      trialExtended: t`Trial extended.`,
      trialExtendedMessage: t`Your trial has been extended successfully`,
      applyingTrialExtension: t`Extending your trial`,
      // Plan Change
      switchPlanCTA: t`Switch plan`,
      changePlanHighlights: t`Highlights`,
      changePlanOfferPunch: t`Discounted secret plans`,
      planChanged: t`Plan changed.`,
      planChangedMessage: t`Your new plan is now in effect`,
      applyingPlanChange: t`Changing your plan...`,
      // Left Theme Step Tags
      surveyStepTag: t`Your feedback`,
      freeFormStepTag: t`Your feedback`,
      finalConfirmation: t`Final confirmation`,
      offerDiscountTag: t`Special offer`,
      offerChangePlanTag: t`Consider other plans`,
      offerExtendTrialTag: t`Extend your trial`,
      offerRedirectTag: t`Let us help`,
      offerContactTag: t`Let us help`,
      offerPauseTag: t`Subscription pause`,
      completeTag: t`Subscription cancelled`,
      errorTag: t`An error occured`,
      offerAccepted: t`Offer accepted`,
    },
  }
}
