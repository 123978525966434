import { config } from 'config'
import { useAppSelector } from 'modules/redux'
import { isRobot } from 'utils/deviceDetection'
import { useScriptInnerHTML, useSSRMounted } from 'utils/hooks'
import { shouldUsePublishedVersion } from 'utils/publishing'

import { selectIsIntercomOpen } from './reducer'

// Code from https://app.intercom.com/a/apps/ihnzqaok/home?step=set_up_messenger_logged_in
// Our INTERCOM_APP_ID comes from our next config.
const INTERCOM_SNIPPET = `
(function(){var w=window;var ic=w.Intercom;if(typeof ic==="function"){ic('reattach_activator');ic('update',w.intercomSettings);}else{var d=document;var i=function(){i.c(arguments);};i.q=[];i.c=function(args){i.q.push(args);};w.Intercom=i;var l=function(){var s=d.createElement('script');s.type='text/javascript';s.async=true;s.src='https://widget.intercom.io/widget/${config.INTERCOM_APP_ID}';var x=d.getElementsByTagName('script')[0];x.parentNode.insertBefore(s,x);};if(document.readyState==='complete'){l();}else if(w.attachEvent){w.attachEvent('onload',l);}else{w.addEventListener('load',l,false);}}})();
`

export const IntercomScript = () => {
  const enabled = !isRobot() && !shouldUsePublishedVersion()
  useScriptInnerHTML(enabled, INTERCOM_SNIPPET)
  const isIntercomOpen = useAppSelector(selectIsIntercomOpen)

  if (!useSSRMounted() || !enabled) return null

  return (
    /**
     * Ensure the intercom widget is force hidden by default
     * Any consumers of the widget will need to override this
     * CSS to make the widget show.
     * This is necessary because intercom will automatically show
     * the borderless frame messenger if a new message is received,
     * which in our app is very disruptive and not the behavior we want.
     */
    <style>
      {/** Override the baseline force hide CSS when we want to show the widget */}
      {`
       #intercom-container > div {
         display: ${isIntercomOpen ? 'block' : 'none'} !important;
       }
       div.intercom-app .intercom-borderless-frame,
       div.intercom-app .intercom-messenger-frame  {
         transform: translateY(calc(${
           isIntercomOpen ? '0px' : '100% + 20px'
         })) !important;
       }
     `}
    </style>
  )
}
